<template>
  <v-card class="d-flex align-center font-weight-bold rounded justify-center pa-0 fill-width fill-height green lighten-4" light outlined @click.prevent="IMPORT_DATA()">
    <v-icon small class="mr-1">
      mdi-file-import
    </v-icon>
    Import
    <div style="width:1px;right:1px;position:fixed;left:-10px;top:-10px;z-index:-1;opacity:0;">
      <xlsx-read :file="file" @parsed="parsed" />
    </div>
    <v-dialog
      v-model="dialogConfirmUpload"
      scrollable
      max-width="475px"
    >
      <v-card>
        <v-card-title class="justify-center">
          IMPORT BULK TTK
        </v-card-title>
        <v-divider />
        <v-card-text v-if="dataLoaded" class="py-3 px-4" style="max-height: 300px;">
          <div v-if="isError.length" class="red--text text-center">
            Kolom header tidak valid, harus terdiri dari kolom :<br>
            <em class="font-weight-bold text-caption">Provinsi, Kabupaten/Kota, Kecamatan, Desa/Kelurahan, Nama, Nomor Telepon, Alamat Lengkap</em>
            <div class="pa-2 rounded grey lighten-4 my-2 text-caption">
              Dan opsional untuk kolom :<br><em class="font-weight-bold">TTK (for an update or external source), Email, Nama Lokasi, Catatan</em><br>
              Gunakan kolom "<em class="font-weight-bold">Group</em>" untuk menentukan grouping pada filter chart/grafik (jika tidak, maka akan otomatis menggunakan "Nama Sheet" sebagai group filter).
            </div>
            <v-chip v-for="(s, iS) in isError" :key="'sheet-' + iS" class="ma-1 red--text font-weight-bold justify-center" color="grey lighten-3" small>
              {{ s.sheet }}
            </v-chip>
          </div>
          <div v-else>
            <v-card v-for="(s, iS) in sheets" :key="'sheet-' + iS" class="pa-4 my-1 d-flex fill-width rounded-lg font-weight-bold justify-center" outlined>
              {{ iS }} : {{ s.length }} ROW(S)
            </v-card>
          </div>
        </v-card-text>
        <v-card-text v-else class="py-6 px-4 text-center" style="max-height: 300px;">
          Loading ...
        </v-card-text>
        <v-divider />
        <v-card-actions class="justify-center">
          <v-btn
            color="red"
            text
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="dialogConfirmUpload = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            :disabled="(!dataLoaded || isError.length) ? true : false"
            color="primary"
            depressed
            small
            class="rounded-pill text-capitalize px-8"
            @click="$emit('result', { sheets, isError }), dialogConfirmUpload = false"
          >
            Continue Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { XlsxRead } from 'vue-xlsx'
export default {
  components: {
    XlsxRead
  },
  data: () => ({
    dialogConfirmUpload: false,
    isError: [],
    dataLoaded: false,
    file: null,
    sheets: {},
    sheetSelected: null
  }),
  methods: {
    validateFields (h) {
      return [
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'PROVINSI'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'KABUPATEN/KOTA'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'KECAMATAN'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'DESA/KELURAHAN'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'NAMA'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'NOMOR TELEPON'),
        h.find(r => (((r && r.v) ? r.v : '') || '').toUpperCase().trim() === 'ALAMAT LENGKAP')
      ].filter(r => !r).length
    },
    parsed (d) {
      this.isError = []
      /* eslint-disable */
      this.dataLoaded = false
      if (d.Strings.length) {
        if (d.SheetNames.length) {
          this.sheets = {}
          Object.keys(d.Sheets).forEach((sheetName) => {
            this.sheets[sheetName] = []
            const dd = d.Sheets[sheetName]
            const headers = [{ col: 'A', v: dd['A1'] }, { col: 'B', v: dd['B1'] }, { col: 'C', v: dd['C1'] }, { col: 'D', v: dd['D1'] }, { col: 'E', v: dd['E1'] }, { col: 'F', v: dd['F1'] }, { col: 'G', v: dd['G1'] }, { col: 'H', v: dd['H1'] }, { col: 'I', v: dd['I1'] }, { col: 'J', v: dd['J1'] }, { col: 'K', v: dd['K1'] }, { col: 'L', v: dd['L1'] }, { col: 'M', v: dd['M1'] }, { col: 'N', v: dd['N1'] }, { col: 'O', v: dd['O1'] }, { col: 'P', v: dd['P1'] }].map(r => {
              if (r && r.v) {
                r.v = (r.v.v || '').replace(/[^\x00-\x7f]/gi, ' ').trim()
                if ((r.v || '').match('Alamat Lengkap')) {
                  r.v = 'Alamat Lengkap'
                } else if ((r.v || '').match('Nama Perpust')) {
                  r.v = 'Nama Lokasi'
                }
                return r
              } else {
                return null
              }
            }).filter(r => r)

            const errFIelds = this.validateFields(headers)
            if (errFIelds) {
              this.isError.push({ sheet: sheetName })
            } else if (headers.length >= 7) {
              const p = {}
              Object.keys(d.Sheets[sheetName]).forEach((v) => {
                const n = d.Sheets[sheetName][v]
                const nn = n ? (n.v + '') : null
                if (nn) {
                  const col = v.substring(0, 1)
                  const row = parseInt(v.substring(1, 12))
                  if (col && row && col !== '!' && row > 1) {
                    const findH = headers.find(x => x.col === col)
                    if (findH && findH.v) {
                      const newKey = (findH.v || '').replace(/[^\x00-\x7f]/gi, ' ').trim()
                      if (!p[row]) {
                        p[row] = {}
                      }
                      p[row][newKey] = (nn || '').replace(/[^\x00-\x7f]/gi, ' ').trim()
                    }
                  }
                }
              })
              Object.keys(p).forEach((v) => {
                this.sheets[sheetName].push(p[v])
              })
            } else {
              this.isError.push({ sheet: sheetName })
              // ERROR COLUMN/FIELD
            }
          })
          setTimeout(() => {
            this.dataLoaded = true
          }, 1500)
          this.sheetSelected = null
          this.dialogConfirmUpload = true
        } else {
          this.$store.dispatch('TOAST', { show: true, message: 'Tidak ada sheet/halaman pada file!' })
        }
      }
    },
    IMPORT_DATA () {
      this.sheets = []
      this.sheetSelected = null
      let u = document.getElementById('file-uploader')
      if (u) {
        u.remove()
      }
      setTimeout(() => {
        u = document.createElement('input')
        u.setAttribute('type', 'file')
        u.setAttribute('id', 'file-uploader')
        u.setAttribute('class', 'file-uploader')
        u.setAttribute('accept', '.xls,.xlsx')
        u.addEventListener('change', (e) => {
          this.file = e.target.files ? e.target.files[0] : null
        })
        document.body.appendChild(u)
        u.click()
      }, 100)
    }
  }
}
</script>
